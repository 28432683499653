import React, { useState, useEffect, useRef } from 'react';

import nightDistance from "./images/nightDistanceFocus.jpeg"
import nightIntermediate from "./images/nightIntermediateFocus.png"
import glare from "./images/glare.png"
import halo from "./images/halo.png"
import streaks from "./images/streaks.png"
import edofRings from "./images/edofRings.png"


export default function NightSimulator(props){


    // console.log(biometerTopographerAxisDiff)
    
    //decrease contrast sensitivity for trifocal lenses
    let contrastLevel = 1;
      props.lensOption === 'Cataract vision'
      ? contrastLevel = 0.6 
      :
      (
        props.lensCorrectsIntermediateVision === 'Yes'
        &
        props.lensCorrectsNearVision === 'Yes'
      )  
        ? contrastLevel = 0.8
        : 
        (
          props.lensCorrectsIntermediateVision === 'Yes'
          &
          props.lensCorrectsNearVision === 'No'
          & 
          props.lensGlare.toLowerCase() === 'edof rings'
        )  
          ? contrastLevel = 0.9
        : 
        (
          props.lensCorrectsIntermediateVision === 'Yes'
          &
          props.lensCorrectsNearVision === 'No'
          & 
          (
            props.lensGlare.toLowerCase() === 'halo'
            ||
            props.lensGlare.toLowerCase() === 'glare'
          )
        )  
          ? contrastLevel = 0.53
          : contrastLevel = 1
        
          // 0.6 for 40 percent reduction in contrast according to Julie Schallhorn article from 2021

    let brightnessLevel = 1;
    props.lensOption === 'Cataract vision'
    ? brightnessLevel = 0.7
    : 
    (
      props.lensCorrectsIntermediateVision === 'Yes'
      &
      props.lensCorrectsNearVision === 'Yes'
    )  
      ? brightnessLevel = 0.88
      : 
      (
        props.lensCorrectsIntermediateVision === 'Yes'
        &
        props.lensCorrectsNearVision === 'No'
        & 
        props.lensGlare.toLowerCase() === 'edof rings'
      )  
        ? brightnessLevel = 0.88
      : 
      (
        props.lensCorrectsIntermediateVision === 'Yes'
        &
        props.lensCorrectsNearVision === 'No'
        & 
        (
          props.lensGlare.toLowerCase() === 'halo'
          ||
          props.lensGlare.toLowerCase() === 'glare'
        )
      )  
        ? brightnessLevel = 0.88
        : brightnessLevel = 1

      // panoptix transmits 88% of light at 3 mm pupil



    //glare pattern parameters for night simulator
      //blur size is zero if corrects astigmatism and doesn't correct near or intermediate
    let blurSize;
      //trifocal blur size should be 3
      (
        props.lensCorrectsIntermediateVision === 'Yes'
        &
        props.lensCorrectsNearVision === 'Yes'
      )  
        ? blurSize = 3
        : 
        // EDOF Symfony should have blur size of 3
        (
          props.lensCorrectsIntermediateVision === 'Yes'
          &
          props.lensCorrectsNearVision === 'No'
          & 
          props.lensGlare.toLowerCase() === 'edof rings'
        )  
          ? blurSize = 3
        : 
        // EDOF Vivity should have blur size of 0
        (
          props.lensCorrectsIntermediateVision === 'Yes'
          &
          props.lensCorrectsNearVision === 'No'
          & 
          (
            props.lensGlare.toLowerCase() === 'halo'
            ||
            props.lensGlare.toLowerCase() === 'glare'
          )
        )  
          ? blurSize = (props.equipmentPreferenceAstig)
          //monofocal lenses without cri should have blur size proportional to amount of corneal astigmatism starting with 2 as the base number and then each additional diopter of cylinder acting as a multiple of that cylinder
        : props.lensCorrectsAstigmatism === 'No' && props.cri !== true
        ? blurSize = (4*(props.equipmentPreferenceAstig))
          //cataract should have a blur size of 10
        : props.lensOption === 'Cataract vision'
        ? blurSize = 10
        //non-presbyopia correcting toric lenses and monofocal lenses with an cri should have a blur amount of 2
        : blurSize = (props.equipmentPreferenceAstig)

        // console.log(blurSize)
        // console.log(props.equipmentPreferenceAstig)
        // console.log(props.astigmatismx)
        // console.log(props.astigmatismy)
        // console.log(Math.pow(props.astigmatismy,2))
        // console.log(Math.sqrt(Math.pow(props.astigmatismx,2)+Math.pow(props.astigmatismy,2)))
    
    let blurOpacity = 80

    //headlight coordinates for nightDistance.jpeg
    let imageWidth = 1600
    let imageHeight = 1443
    let coordinates = [
      {name: 'fp', x: 1072, y: 876, size: 66},
      {name: 'fd', x: 1276, y: 876, size: 66},
      {name: 'hdt', x: 1528, y:862, size: 50},
      {name: 'hdb', x: 1528, y: 834, size: 20},
      {name: 'cd', x: 1038, y: 842, size: 38},
    ]

    //change database glare pattern to image file name
    let glarePattern;
    props.lensOption === 'Cataract vision'
    ? glarePattern = glare
    : props.lensGlare.toLowerCase() === 'edof rings'
    ? glarePattern = edofRings
    : props.lensGlare.toLowerCase() === 'halo'
    ? glarePattern = halo
    : glarePattern = streaks
    // : glarePattern = props.lensGlare.toLowerCase()

    // useEffect(() => {
    //   window.scrollTo(0, 0)
    // }, [])


  return(

<>
             { 
                coordinates.map((m,i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        position: 'absolute',
                        bottom: '0px',
                        right: -0.34*props.aspectHeight,
                        width: 0.89*props.aspectHeight,
                      }}
                    >
                     
                      <div 
                        style={{
                          position: 'relative',
                          width: 0.89*props.aspectHeight,
                          height: 0.8*props.aspectHeight
                        }}
                      >
                        <img
                          className=''
                          style={{
                            position: 'absolute',
                            zIndex: '5',
                            
                            bottom: `${(((m.y-(m.size*blurSize/2-m.size/2))) / imageHeight*1) * 0.8*props.aspectHeight}px`,
                            
                            left: `${(((m.x-(m.size*blurSize/2-m.size/2))) / imageWidth*1) * (0.8*props.aspectHeight/0.9)}px`,
                            
                            transform: `rotate(${props.equipmentPreferenceAxis*1}deg)`,
                            
                            width: `${((m.size*blurSize) / imageWidth*1) * (0.8*props.aspectHeight/0.9)}px`,
                            
                            opacity: `${blurOpacity}%`,
                            filter: `blur(${props.intermediateBlur*1.5 + props.cataractBlur*1}px)`,
                          }}
                          src={glarePattern} 
                          alt="glare pattern"
                          />
                      </div>
                    </div>
                  )
                })
              }
              
              
              
              <img
                className='intermediateImage'
                style={{
                  position: 'absolute',
                  zIndex: '3',
                  bottom: `${props.astigmatismy*2}px`,
                  right: `${-0.34*props.aspectHeight+props.astigmatismx*2}px`,
                  width: 0.196*props.aspectWidth,
                  opacity: '28%',
                  filter: `blur(${props.intermediateBlur*1.5 + props.cataractBlur*1}px) contrast(${contrastLevel}) brightness(${brightnessLevel})`,
                  borderRadius: '0 20px 20px 0',
                }}
                src={nightIntermediate}
                alt="Intermediate"
                />

              <img
                className='intermediateImage'
                style={{
                  position: 'absolute',
                  zIndex: '3',
                  bottom: `${(-1*props.astigmatismy*2)}px`,
                  right: `${-0.34*props.aspectHeight-1*(props.astigmatismx*2)}px`,
                  width: 0.196*props.aspectWidth,
                  opacity: '28%',
                  filter: `blur(${props.intermediateBlur*1.5 + props.cataractBlur*1}px) contrast(${contrastLevel}) brightness(${brightnessLevel})`,
                  borderRadius: '0 20px 20px 0',
                }}
                src={nightIntermediate}
                alt="Intermediate"
                />

              <img
                className='intermediateImage'
                style={{
                  position: 'absolute',
                  zIndex: '2',
                  bottom: '0px',
                  right: -0.34*props.aspectHeight,
                  width: 0.196*props.aspectWidth,
                  opacity: '100%',
                  filter: `blur(${props.intermediateBlur*1.5 + props.cataractBlur*1}px) contrast(${contrastLevel}) brightness(${brightnessLevel})`,
                  borderRadius: '0 20px 20px 0',
                }}
                src={nightIntermediate}
                alt="Intermediate"
                />

              <img
                className='distanceImage'
                style={{
                  position: 'absolute',
                  zIndex: '1',
                  bottom: `${props.astigmatismy*3}px`,
                  right: `${-0.34*props.aspectHeight+props.astigmatismx*3}px`,
                  height: 0.8*props.aspectHeight,
                  opacity: '28%',
                  filter: `blur(${props.distanceBlur*1.5 + props.cataractBlur*1}px) contrast(${contrastLevel}) brightness(${brightnessLevel})`,
                  borderRadius: '0 20px 20px 0',
                }}
                src={nightDistance}
                alt="Distance"
                />

              <img
                className='distanceImage'
                style={{
                  position: 'absolute',
                  zIndex: '1',
                  bottom: `${-props.astigmatismy*3}px`,
                  right: `${-0.34*props.aspectHeight-props.astigmatismx*3}px`,
                  height: 0.8*props.aspectHeight,
                  opacity: '28%',
                  filter: `blur(${props.distanceBlur*1.5 + props.cataractBlur*1}px) contrast(${contrastLevel}) brightness(${brightnessLevel})`,
                  borderRadius: '0 20px 20px 0',
                }}
                src={nightDistance}
                alt="Distance"
                />

              <img
                className='distanceImage'
                style={{
                  position: 'absolute',
                  zIndex: '0',
                  bottom: '0px',
                  right: -0.34*props.aspectHeight,
                  height: 0.8*props.aspectHeight,
                  opacity: '100%',
                  filter: `blur(${props.distanceBlur*1.5 + props.cataractBlur*1}px) contrast(${contrastLevel}) brightness(${brightnessLevel})`,
                  borderRadius: '0 20px 20px 0',
                }}
                src={nightDistance}
                alt="Distance"
                />

              </>



  )
}
