import React, { Fragment, useEffect, useState } from 'react';

export default function CriPicker (props) {

  const[criPicker, setCriPicker]=useState('')


  let criAlwaysOff =
  (
    props.criOffer === 'No'
    ||
    (props.biometerTopographerAstigDiff*1 > props.criBioTopoAstigMax*1)
    ||
    (props.biometerTopographerAxisDiff*1 > props.criBioTopoAxisMax*1)
    ||
    props.lensOption === 'Cataract vision'
    ||
    props.lensCriRequire === "No (don't offer)"
  )
    ? true
    : false

    // console.log(criAlwaysOff)

  let criAlwaysOn =
  (
    //Require CRI if corneal astig from preferred machine is greater than lensCriMinAstig and less than lensCriMaxAstig
    (
      props.equipmentPreferenceAstig*1 > props.lensCriMinAstig
      &&
      props.equipmentPreferenceAstig*1 < props.lensCriMaxAstig
    )
    &&
    //and current selection is not "Cataract vision"
    props.lensOption !== 'Cataract vision'
    &&
    //and physician requires CRI for thiss lens (props.lensCriRequire === "Yes (always)")
    props.lensCriRequire === "Yes (always)"
  )
    ? true
    : false

    // console.log(criAlwaysOn)

  let criAllowToggle =
  (
    // If corneal astig from preferred machine is greater than lensCriMinAstig and less than lensCriMaxAstig
    (
      props.equipmentPreferenceAstig*1 > props.lensCriMinAstig
      &&
      props.equipmentPreferenceAstig*1 < props.lensCriMaxAstig
    )
    &&
    //and current selection is not "Cataract vision"
    props.lensOption !== 'Cataract vision'
    &&
    //and CRI is optional for this lens
    props.lensCriRequire === "Optional (always offered)"
  )
    ? true
    : false

    // console.log(criAllowToggle)

    let criModifier = () => {
      if (criAlwaysOff === true){
        setCriPicker('alwaysOff')
      } else if (criAlwaysOff === false && criAlwaysOn === true){
        setCriPicker('alwaysOn')
      } else if (criAlwaysOff === false && criAlwaysOn === false && criAllowToggle === true){
        setCriPicker('allowToggle')
      } else if (criAlwaysOff === false && criAlwaysOn === false && criAllowToggle === false){
        setCriPicker('notNeeded')
      }
    }

    useEffect(() => {
      criModifier();
    });




    //NB: may be able to get rid of useEffect in Lens.js and LensNew.js for props.lensFlacsRequire


    //If cri === true and props.lensFlacsRequire === "Yes (only with CRI)", then props.setFlacs(true)

    useEffect(() => {
      if(criPicker === 'alwaysOff'){
        props.setCri(false)
        props.setCriAlwaysOn(false)
        props.setCriAllowToggle(false)
      } else if(criPicker === 'alwaysOn' && props.lensFlacsRequire === "Yes (only with CRI)"){
        props.setCri(true)
        props.setFlacs(true)
        props.setCriAlwaysOn(true)
        props.setFlacsAlwaysOn(true)
        props.setCriAllowToggle(false)
        props.setFlacsAllowToggle(false)
      } else if(criPicker === 'alwaysOn'){
        props.setCri(true)
        props.setCriAlwaysOn(true)
        props.setCriAllowToggle(false)
      } else if(criPicker === 'allowToggle'){
        props.setCri(false)
        props.setCriAlwaysOn(false)
        props.setCriAllowToggle(true)
      } else if(criPicker === 'notNeeded'){
        props.setCri(false)
        props.setCriAlwaysOn(false)
        props.setCriAllowToggle(false)
      }
    }, [criPicker]);


    let buttonCriStyleTrue = {
      width: '100%',
      border: '1px solid #292929',
      margin: '10px 0 0 0',
      color: '#292929',
      padding: '14px 8px',
      borderRadius: '5px',
      fontSize: '14px',
      fontWeight: '400',
      background: '#f1f1f1',display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',

      display: 'flex',
      justifyContent: 'space-between',
    };
    
    const buttonCriStyleFalse = {
      width: '100%',
      border: '1px solid #bbb',
      margin: '10px 0 0 0',
      color: '#888',
      padding: '14px 8px',
      borderRadius: '5px',
      fontSize: '14px',
      fontWeight: '400',
      background: '#FFF',display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',

      display: 'flex',
      justifyContent: 'space-between',
    };

    let buttonCriStyleOff = {
      width: '100%',
      border: '1px solid #bbb',
      margin: '10px 0 0 0',
      color: '#888',
      padding: '14px 8px',
      borderRadius: '5px',
      fontSize: '14px',
      fontWeight: '400',
      background: '#FFF',display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',

      display: 'flex',
      justifyContent: 'space-between',
    }

  return(
    <Fragment>
      {criAlwaysOff === true
        ? (null)
        : props.criAlwaysOn === true
          ?
            (
              <div
                style={buttonCriStyleTrue}
                >
                <p style={{margin: '0 6px'}}>
                  Corneal relaxing incisions *required*
                </p>
                <p style={{margin: '0 6px'}}>
                  ${props.lensCriFee}
                </p>
              </div>
            )
          : props.criAllowToggle === true
            ?
              <div
                style={
                  props.cri === true
                   ? buttonCriStyleTrue
                   : buttonCriStyleFalse
                 }
                 onClick={
                   props.cri === false
                     ? (props.lensFlacsRequire === 'Yes (only with CRI)')
                        ? () => {
                                  props.setCri(true)
                                  props.setFlacs(true)
                                }
                        : () => props.setCri(true)
                     : (props.lensFlacsRequire === 'Yes (only with CRI')
                        ? () => {
                               props.setCri(false)
                               props.setFlacs(false)
                             }
                        : () => props.setCri(false)
                   }
                >
                <p style={{margin: '0 6px'}}>
                  Corneal relaxing incisions
                </p>
                <p style={{margin: '0 6px'}}>
                  ${props.lensCriFee} 
                </p>
              </div>
            : 
              <div
                style={buttonCriStyleOff}
                >
                <p style={{margin: '0 6px', textDecoration: 'line-through',}}>
                  Corneal relaxing incisions
                </p>
                <p style={{margin: '0 6px', color: 'red'}}>
                  *Not an option*
                </p>
              </div>


        }
      </Fragment>
    )
}
