import React, { useEffect, useState } from 'react';
import { FaFontAwesome } from 'react-icons/fa';

export default function FeePicker (props) {

  const[feeTotal, setFeeTotal]=useState('')
  const[feePhysician, setFeePhysician]=useState('')
  const[feeHospital, setFeeHospital]=useState('')


  let lensTotal = (props.lensPhysicianFee*1 + props.lensHospitalFee*1)


  let feeModifier = () => {
    if (
      props.cri === false
      &&
      props.flacs === false
      &&
      lensTotal === 0
    ){
      setFeeTotal(0)
      setFeePhysician(0)
      setFeeHospital(0)

    } else if (
      props.cri === false
      &&
      props.flacs === false
    ){
      setFeeTotal(lensTotal)
      setFeePhysician(props.lensPhysicianFee*1)
      setFeeHospital(props.lensHospitalFee*1)

    } else if (
      props.cri === false
      &&
      props.flacs === true
    ){
      setFeeTotal(props.lensFlacsFee*1 + lensTotal*1)
      setFeePhysician(props.lensPhysicianFee*1)
      setFeeHospital(props.lensHospitalFee*1 + props.lensFlacsFee*1)
    
    } else if (
      props.cri === true
      &&
      props.flacs === false
    ){
      setFeeTotal(props.lensCriFee*1 + lensTotal*1)
      setFeePhysician(props.lensPhysicianFee*1)
      setFeeHospital(props.lensHospitalFee*1 + props.lensCriFee*1)

    } else if (
      props.cri === true
      &&
      props.flacs === true
    ){
      setFeeTotal(props.lensCriFee*1 + props.lensFlacsFee*1 + lensTotal*1)
      setFeePhysician(props.lensPhysicianFee*1 + props.lensCriFee*1)
      setFeeHospital(props.lensHospitalFee*1 + props.lensFlacsFee*1)

    } else {
      setFeeTotal('Consult with your doctor')
    }
  }

    useEffect(() => {
      feeModifier()
      props.setOopTotal(feeTotal)
      props.setOopPhysician(feePhysician)
      props.setOopHospital(feeHospital)
    });

  let feeStyle = {
    padding: '0 6px',
    fontWeight: '400',
    fontSize: '14px',
  }

    return(
      <>
        {
          props.thisLens === "Cataract vision"
          ? 
            <div style={feeStyle}>
            </div>
          : 
          props.thisLensHospitalFee*1 + props.thisLensPhysicianFee*1 === 0 
          ? 
            <div style={feeStyle}>
              Covered by insurance
            </div>
          :
          props.financing === true
          ? <div style={feeStyle}>
              ${props.thisLensHospitalFee*1} down + ${(Math.round((props.thisLensPhysicianFee*1)/6))}/mo
            </div>
          : <div style={feeStyle}>
              ${props.thisLensPhysicianFee*1+props.thisLensHospitalFee*1}
            </div>
        }
      </>
    )

  
}
