import React, { Fragment, useEffect, useState } from 'react';

export default function FlacsPicker (props) {

  const[flacsPicker, setFlacsPicker]=useState('')

  let flacsAlwaysOff =
  (
    //current selection is "Cataract vision"
    props.lensOption === 'Cataract vision'
    ||
    //or contraindications
    // props.ptContraFilterLength > 0
    // ||
    //or doctor doesn't offer flacs at all
    props.flacsOffer === 'No'
    ||
    //or doctor doesn't offer flacs for this lens
    props.lensFlacsRequire === "No (don't offer)"
  )
    ? true
    : false

    // console.log(flacsAlwaysOff)

  let flacsAlwaysOn =
  (
    //current selection is not "Cataract vision"
    props.lensOption !== 'Cataract vision'
    &&
    //and physician requires FLACS for this lens (props.lensFlacsRequire === "Yes (always)")
    (
      (
        props.flacsOffer === 'Yes'
        &&
        props.lensFlacsRequire === "Yes (always)"
      )
      ||
      (
        props.flacsOffer === 'Yes'
        &&
        props.criOffer === 'Yes'
        &&
        props.lensFlacsRequire === "Yes (only with CRI)"
        &&
        props.cri === true
      )
    )
  )
    ? true
    : false

    // console.log(flacsAlwaysOn)

  let flacsAllowToggle =
  (
    //current selection is not "Cataract vision"
    props.lensOption !== 'Cataract vision'
    &&
    //and FLACS is optional for this lens
    props.flacsOffer === 'Yes'
    &&
    (
      props.lensFlacsRequire === "Optional (always offered)"
      ||
      (
        props.lensFlacsRequire === "Optional (only offered with CRI)"
        &&
        props.cri === true
      )
    )
  )
    ? true
    : false

    // console.log(flacsAllowToggle)

    let flacsModifier = () => {
      if (flacsAlwaysOff === true){
        setFlacsPicker('alwaysOff')
      } else if (flacsAlwaysOff === false && flacsAlwaysOn === true){
        setFlacsPicker('alwaysOn')
      } else if (flacsAlwaysOff === false && flacsAlwaysOn === false && flacsAllowToggle === true){
        setFlacsPicker('allowToggle')
      } else if (flacsAlwaysOff === false && flacsAlwaysOn === false && flacsAllowToggle === false){
        setFlacsPicker('notNeeded')
      }
    }

    useEffect(() => {
      flacsModifier();
    });

    useEffect(() => {
      if(flacsPicker === 'alwaysOff'){
        props.setFlacs(false)
        props.setFlacsAlwaysOn(false)
        props.setFlacsAllowToggle(false)
      } else if(flacsPicker === 'alwaysOn'){
        props.setFlacs(true)
        props.setFlacsAlwaysOn(true)
        props.setFlacsAllowToggle(false)
      } else if(flacsPicker === 'allowToggle'){
        props.setFlacs(false)
        props.setFlacsAlwaysOn(false)
        props.setFlacsAllowToggle(true)
      } else if(flacsPicker === 'notNeeded'){
        props.setFlacs(false)
        props.setFlacsAlwaysOn(false)
        props.setFlacsAllowToggle(false)
      }
    }, [flacsPicker]);


    const buttonFlacsStyleTrue = {
      width: '100%',
      border: '1px solid #292929',
      margin: '10px 0 0 0',
      color: '#292929',
      padding: '14px 8px',
      borderRadius: '5px',
      fontSize: '14px',
      fontWeight: '400',
      background: '#f1f1f1',display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',

      display: 'flex',
      justifyContent: 'space-between',
    };
  
    const buttonFlacsStyleFalse = {
      width: '100%',
      border: '1px solid #bbb',
      margin: '10px 0 0 0',
      color: '#888',
      padding: '14px 8px',
      borderRadius: '5px',
      fontSize: '14px',
      fontWeight: '400',
      background: '#FFF',display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',

      display: 'flex',
      justifyContent: 'space-between',
    };
    

  return(
    <Fragment>
      {flacsAlwaysOff === true
        ? (null)
        : props.flacsAlwaysOn === true
          ?
            (
              <div
                style={buttonFlacsStyleTrue}
                >
                <p style={{margin: '0 6px'}}>
                  Laser-assisted (FLACS) *required*
                </p>
                <p style={{margin: '0 6px'}}>
                  ${props.lensFlacsFee} 
                </p>
              </div>
            )
          : props.flacsAllowToggle === true
            ?
              <div
                style={
                  props.flacs === true
                   ? buttonFlacsStyleTrue
                   : buttonFlacsStyleFalse
                 }
                 onClick={
                   props.flacs === false
                     ? () => props.setFlacs(true)
                     : () => props.setFlacs(false)
                   }
                >
                <p style={{margin: '0 6px'}}>
                  Laser-assisted (FLACS)
                </p>
                <p style={{margin: '0 6px'}}>
                  ${props.lensFlacsFee} 
                </p>
              </div>
            : 
              <div
                style={buttonFlacsStyleFalse}
                >
                <p style={{margin: '0 6px', textDecoration: 'line-through'}}>
                  Laser-assisted (FLACS)
                </p>
                <p style={{margin: '0 6px', color: 'red'}}>
                  *Not an option*
                </p>
              </div>


        }
      </Fragment>
    )
}
